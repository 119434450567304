import React, { useContext } from "react";
import Context from "../../context/Context";
import { useOrdersData } from "../../hooks/useOrdersData";
import { useCurrentOffer } from "../../hooks/useCurrentOffer";

const UpsellTextComponent = ({
  items,
  defaultIndex,
  forceDefaultIndex,
  attributes,
  removeParagraphMargins
}) => {
  const {
    selectorData: {
      currency: { symbol: currencySymbol }
    }
  } = useContext(Context);

  const {
    cart_total_before_discounts,
    checkout_discount_amount,
    cart_total_with_discounts,
    customer_first_name,
    customer
  } = useOrdersData();

  const {
    discountRate = "00",
    productName = "No Product Name",
    displayTitle = "No Display Title",
    checkoutData,
    offerIndex,
    tax: taxAmount,
    taxLabel
  } = useCurrentOffer(defaultIndex, forceDefaultIndex);

  const {
    title = "",
    discounted_price = "0.00",
    price = "0.00",
    quantity = 2
  } = checkoutData;

  const savingsPrice = parseFloat(price) - parseFloat(discounted_price);

  const totalPrice = price + parseFloat(cart_total_before_discounts);
  const totalSavingPrice = parseFloat(checkout_discount_amount) + savingsPrice;
  const totalDiscountedPrice =
    parseFloat(cart_total_with_discounts) + discounted_price;

  const InnerHTML = () => {
    let { text } = items[offerIndex] ?? { text: "" };

    // product data
    text = text?.replace(/\[title\]/gm, title);
    text = text?.replace(/\[display-title\]/gm, displayTitle);
    text = text?.replace(
      /\[discounted-price\]/gm,
      parseFloat(discounted_price).toFixed(2) ?? 0.0
    );
    text = text?.replace(
      /\[discounted-price-whole\]/gm,
      Math.trunc(parseFloat(discounted_price)).toFixed(0) ?? 0
    );
    text = text?.replace(
      /\[discounted-price-decimal\]/gm,
      ((parseFloat(discounted_price) % 1) * 100).toFixed(0) ?? 0
    );
    text = text?.replace(
      /\[discounted-price-per-tub\]/gm,
      (parseFloat(discounted_price) / quantity).toFixed(2) ?? 0.0
    );
    text = text?.replace(/\[price\]/gm, parseFloat(price).toFixed(2) ?? 0.0);
    text = text?.replace(
      /\[regular-price\]/gm,
      parseFloat(price).toFixed(2) ?? 0.0
    );
    text = text?.replace(/\[savings\]/gm, savingsPrice.toFixed(2) ?? 0.0);
    text = text?.replace(/\[savings-price\]/gm, savingsPrice.toFixed(2) ?? 0.0);
    text = text?.replace(/\[discount-rate\]/gm, discountRate);
    text = text?.replace(/\[product-name\]/gm, productName);
    text = text?.replace(/\[currency-symbol\]/gm, currencySymbol);

    // new order total
    text = text?.replace(
      /\[new-order-total-savings\]/gm,
      totalSavingPrice.toFixed(2) ?? 0.0
    );
    text = text?.replace(
      /\[new-order-total-price\]/gm,
      parseFloat(totalPrice).toFixed(2) ?? 0.0
    );
    text = text?.replace(
      /\[new-order-total-discounted-price\]/gm,
      parseFloat(totalDiscountedPrice).toFixed(2) ?? 0.0
    );

    // taxes
    text = text?.replace(/\[tax-label\]/gm, taxLabel || "Sales tax");
    text = text?.replace(/\[tax-amount\]/gm, parseFloat(taxAmount).toFixed(2));

    // Amount to be charged
    text = text?.replace(
      /\[charge-amount\]/gm,
      (parseFloat(discounted_price) + parseFloat(taxAmount)).toFixed(2)
    );

    // order data
    text = text?.replace(
      /\[customer-firstname\]/gm,
      customer_first_name || "..."
    );
    if (customer) {
      Object.keys(customer).map(key => {
        text = text?.replace(`[customer-${key}]`, customer[key] || "...");
        return null;
      });
    }

    return {
      __html: text
    };
  };

  Object.assign(attributes, {
    className: `${attributes.className} upsell-text ${
      removeParagraphMargins ? `remove-margins` : ``
    }`
  });

  return <div {...attributes} dangerouslySetInnerHTML={InnerHTML()} />;
};

export default UpsellTextComponent;
